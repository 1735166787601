<template>
	<tr class="tr-header">
		<td>{{ invoice.created_at | date }}</td>
		<td>{{ invoice.period | period }}</td>
		<td>{{ invoice.due_at | date }}</td>
		<td>{{ invoice.amount | price }}</td>
		<td>
			<!-- review | pending | paid | canceled | scheduled -->
			<b class="status" :class="style_status">
				{{ status_invoice }}
				<img v-if="showWarning" src="/icons/warning-red.svg" alt="" v-b-tooltip="overdue_tooltip" />
			</b>
		</td>
		<td width="30">
			<a :href="invoice.url" target="_blank">
				<img src="/icons/export2.svg" alt="" />
			</a>
		</td>
	</tr>
</template>

<script>
import moment from "moment";
import { parseNumberToMoney } from '@/utils/moneyHelper';

export default {
	name: "InvoicesTableRow",
	props: {
		invoice: {
			type: Object,
			required: true
		}
	},

	filters: {
		date: (date) => moment(date).format('DD/MM/YYYY'),
		period: (obj) => `${moment(obj.start_at).format('DD/MM/YYYY')} - ${moment(obj.end_at).format('DD/MM/YYYY')}`,
		price: (value) => 'R$' + parseNumberToMoney(value).trim().split('$')[1]
	},

	computed: {
		showWarning () { return this.style_status === 'warning' },

		style_status () {
			const status = this.invoice.status
			let style = 'available'
			if (status === 'paid') style = 'complete'
			if (status === 'canceled' || status === 'review') style = 'warning'
			if (this.overdue < 0 && status !== 'paid') style = 'warning'

			return style
		},

		overdue () {
			return moment(this.invoice.due_at).diff(moment.now(), 'days')
		},

		overdue_tooltip () {
			const overdue = Math.abs(this.overdue)
			return `Atenção, fatura vencida há ${overdue} dias`
		},

		status_invoice () {
			// review | pending | paid | canceled | scheduled
			const status = this.invoice.status
			let returned = ''
			switch (status) {
				case 'pending':
					if (this.overdue < 0) {
						returned = 'Vencido'
					} else {
						returned = 'Disponível'
					}
					break
				case 'paid': returned = 'Pago'
					break
				case 'canceled': returned = 'Cancelado'
					break
			}

			return returned
		}
	}
}
</script>

<style lang="scss" scoped>
th, td {
	padding: 14px !important;
	vertical-align: middle;
	border-top: none;
}

.status {
	img{ display: inline-flex; width: 20px; margin-top: -2px; }
}
.status.available{color: #305BF2;}
.status.warning{color: #F63220;}
.status.complete{color: #069763;}
</style>
